import type { FunctionComponent } from 'react';

import { Image } from '@/domains/core/Image/Image';
import { PLATFORMS } from '@/domains/core/settings/constants';
import { getPlatform } from '@/domains/core/settings/utils';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { getAppsData } from '@/domains/proB2BAnimation/utils/getMobileAppsData';
import { Accordion } from '@/domains/retention/Footer/modules/Accordion/Accordion';
import { GTM_CLICK_ON_APP } from '@/domains/retention/Footer/tracking/gtmEvents';
import {
  availablePlayStore,
  downloadAppStore,
} from '@/domains/retention/Footer/translations';

import appstoreDE from './assets/appstore-de.svg?url';
import appstoreES from './assets/appstore-es.svg?url';
import appstoreFR from './assets/appstore-fr.svg?url';
import appstoreGB from './assets/appstore-gb.svg?url';
import appstoreIT from './assets/appstore-it.svg?url';
import googleplayDE from './assets/google-play-de.png';
import googleplayES from './assets/google-play-es.png';
import googleplayFR from './assets/google-play-fr.png';
import googleplayGB from './assets/google-play-gb.png';
import googleplayIT from './assets/google-play-it.png';

import styles from './MobileApps.module.scss';

export const appstore = {
  [PLATFORMS.GERMANY]: appstoreDE,
  [PLATFORMS.SPAIN]: appstoreES,
  [PLATFORMS.FRANCE]: appstoreFR,
  [PLATFORMS.GREAT_BRITAIN]: appstoreGB,
  [PLATFORMS.ITALY]: appstoreIT,
};

export const googleplay = {
  [PLATFORMS.GERMANY]: googleplayDE,
  [PLATFORMS.SPAIN]: googleplayES,
  [PLATFORMS.FRANCE]: googleplayFR,
  [PLATFORMS.GREAT_BRITAIN]: googleplayGB,
  [PLATFORMS.ITALY]: googleplayIT,
};

export interface MobileAppsProps {
  forceB2B?: boolean;
}

export const MobileApps: FunctionComponent<MobileAppsProps> = ({
  forceB2B = false,
}) => {
  const { appStoreUrl, playStoreUrl, title, subTitle } = getAppsData(forceB2B);
  const platform = getPlatform();

  return (
    <div className={styles.container}>
      <Accordion title={title} subTitle={subTitle} isExpandable={false}>
        <div className={styles.logos}>
          <a
            href={appStoreUrl}
            data-testid="appstore-button"
            rel="noreferrer"
            target="_blank"
            onClick={() =>
              Gtm.push(GTM_CLICK_ON_APP({ id: 'App Store', url: appStoreUrl }))
            }
          >
            <Image
              alt={downloadAppStore}
              width={116}
              height={37}
              src={appstore[platform]}
            />
          </a>
          <a
            href={playStoreUrl}
            data-testid="playstore-button"
            rel="noreferrer"
            target="_blank"
            onClick={() =>
              Gtm.push(
                GTM_CLICK_ON_APP({
                  id: 'Google Play',
                  url: playStoreUrl,
                }),
              )
            }
          >
            <Image
              alt={availablePlayStore}
              width={124}
              height={37}
              src={googleplay[platform]}
            />
          </a>
        </div>
      </Accordion>
    </div>
  );
};

MobileApps.displayName = 'MobileApps';
